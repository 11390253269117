import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Media } from "../components/atoms/Media";
import MobileNavigator from "../components/atoms/MobileNavigator";
import FooterMobile from "../components/MobileViewComponents/Footer/FooterMobile";
import Footer from "../components/organisms/Footer";
import Header from "../components/organisms/Header";
import ToolBarSection from "../components/organisms/ToolBarSection";
import WhatsappButton from "../components/atoms/WhatsappButton";
import MobileToolBarSection from "../components/MobileViewComponents/Toolbar/ToolbarMobile";
import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/locale/ar";
import { Icon } from "@iconify/react";
import { Grid, Pagination } from "@mui/material";
// import BlogData from "../content/BlogsData";

const ContainerStyle = styled(Container)`
  margin: 0 auto;
  width: 80% !important;
  min-height: 80vh;
  @media (max-width: 768px) {
    width: 95%;
  }
`;
const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(220deg, #dee2f0 0%, #fbfbfb00 75%);
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;
`;

const Blogs = () => {

  type BlogsProps = {
    head: string;
    content: string;
    dec: string;
    imageCover: any;
    imageThumb: any;
    publishedAt: string;
    id: number;
    link: string;
    uniqueUrlName: string;
    latest?: boolean;
  };

  const apiUrl =
    "https://blogs-be.azurewebsites.net/Posts?status=2&pageSize=100";

  const [BlogData, setBlogData] = useState([]);
  const [search, setSearch] = useState<any>(null);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  const getBlogs = async () => {
    let options = {
      method: "GET",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      params: JSON.stringify({
        limit: 100,
        status: 2,
      }),
    };

    setLoading(true);

    await fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("this is data", data);
        setBlogData(data?.data);
        setFilteredUsers(data?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(error);
      });

    setLoading(false);
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const handleInputChange = (e: any) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    const filteredItems = BlogData.filter(
      (user) =>
        user.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.head.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredUsers(filteredItems);
  };

  console.warn("first");
  const BlogsCard = ({
    head,
    dec,
    content,
    imageCover,
    imageThumb,
    publishedAt,
    id,
    latest,
    link,
    uniqueUrlName,
  }: BlogsProps) => {
    return (
      <div style={{ margin: "20" }}>
        {latest ? (
          <>
            <Media greaterThan="sm">
              <img
                loading="eager"
                src={imageCover}
                alt={head}
                placeholder="blurred"
                style={{
                  // width: "1216px",
                  // height: "516px"
                  objectFit: "fill",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Media>
            <Media at="sm">
              <img
                loading="eager"
                src={imageThumb}
                alt={head}
                placeholder="blurred"
                // objectFit='fill'
                style={{
                  // width: "1216px",
                  // height: "516px"
                  objectFit: "fill",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Media>
          </>
        ) : (
          <img
            loading="eager"
            src={imageThumb}
            alt={head}
            placeholder="blurred"
            style={{
              maxHeight: "600px",
              objectFit: "fill",
              width: "100%",
              height: "100%",
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          <p
            style={{
              fontSize: "1.5rem",
              direction: "rtl",
              color: "#DDB669",
              marginTop: "0rem",
              marginBottom: "0rem",
            }}
          >
            {moment(publishedAt).format("LL")}
          </p>
          {/* <a href={`/blog/${link}`}  rel="noopener noreferrer"> */}

          <a
            href={`/blog/${uniqueUrlName}`}
            rel="noopener noreferrer"
            style={{
              fontSize: "2rem",
              direction: "rtl",
              marginBottom: "0rem",
              marginTop: "0rem",
              color: "black",
            }}
          >
            {head}
          </a>
          {/* </a> */}

          <p
            style={{
              fontSize: "1.5rem",
              direction: "rtl",
              marginTop: "0rem",
              marginBottom: "0rem",
              color: "#475467",
            }}
          >
            {dec}
          </p>
          <a
            href={`/blog/${uniqueUrlName}`}
            // target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              flexDirection: "row",
              direction: "rtl",
              alignItems: "center",
              marginTop: "1rem",
              gap: "0.5rem",
            }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                direction: "rtl",
                color: "#DDB669",
                marginTop: "0rem",
                marginBottom: "0rem",
              }}
            >
              {"قراءة المقال"}
            </p>
            <Icon color="#DDB669" icon="akar-icons:arrow-up-left" />
          </a>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          style={{
            width: "40px",
            height: "40px",
            color: "#DDB669",
          }}
        />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="تجديد عقد ايجار
طريقة تجديد عقد ايجار
رسوم تجديد عقد الإيجار
تجديد العقد"
        />
        <meta
          name="description"
          content="إجابات وافية على جميع استفساراتك حول الخدمات القانونية التي نقدمها في شورى لضمان تجربة مستخدم ممتازة ومطمئنة."
        />
        <title>
          تجديد عقد ايجار في السعودية | طريقة تجديد عقد ايجار | شورى
        </title>
        <link
          rel="canonical"
          href={
            typeof window !== "undefined"
              ? `${window.location.origin}${window.location.pathname}`
              : ""
          }
        />{" "}
      </Helmet>
      <ContainerStyle fluid>
        <Media at="sm">
          <MobileNavigator link="/" title="المدونة" />
          <div style={{ marginTop: "1rem", alignItems: "flex-start" }}>
            <p style={{ direction: "rtl" }} className="">
              يوفر تطبيق شورى استشارات وخدمات قانونية عن طريق ربط العملاء
              بمجموعة من المحامين المرخصين من قبل وزارة العدل السعودية، مما
              يعطيهم فرصة للحصول على المشورة القانونية بسهولة ومهنية عالية.
            </p>
          </div>
          <input
            className={search ? "" : "icon"}
            placeholder="بحث عن .."
            type="search"
            value={search}
            onChange={handleInputChange}
            style={{
              width: "60%",
              height: "4.5rem",
              backgroundColor: "white",
              border: "1px solid #ebebeb",
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              marginBlockStart: "3rem",
              borderRadius: "0.8rem",
              direction: "rtl",
              padding: "1rem",
            }}
          ></input>
          {!search && (
            <div style={{ marginTop: "5rem", alignItems: "flex-start" }}>
              <p
                style={{
                  fontSize: "2rem",
                  direction: "rtl",
                  marginBottom: "2rem",
                }}
              >
                المقالات الاخيرة
              </p>
              {BlogData.slice(0, 1)?.map((service, index) => {
                const {
                  head,
                  content,
                  content2,
                  imageCover,
                  imageThumb,
                  publishedAt,
                  link,
                  uniqueUrlName,
                  id,
                  imageUrl,
                } = service;
                return (
                  <BlogsCard
                    latest
                    dec={content2}
                    head={head}
                    content={content}
                    imageCover={imageUrl}
                    imageThumb={imageUrl}
                    link={link}
                    uniqueUrlName={uniqueUrlName}
                    publishedAt={publishedAt}
                    id={id}
                  />
                );
              })}
            </div>
          )}

          {BlogData?.length > 1 && (
            <div style={{ marginTop: "5rem", alignItems: "flex-start" }}>
              <p
                style={{
                  fontSize: "2rem",
                  direction: "rtl",
                  marginBottom: "2rem",
                }}
              >
                {search ? `نتائج البحث` : `مقالات اخرى`}
              </p>
              {filteredUsers?.length < 1 ? (
                <StaticImage
                  src="../images/blogs/Not-found.png"
                  alt="app-image"
                  placeholder="blurred"
                  objectFit="contain"
                  style={{
                    width: "20%",
                    height: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                  }}
                />
              ) : (
                <Grid
                  direction={"row-reverse"}
                  rowSpacing={5}
                  columnSpacing={5}
                  container
                >
                  {(search ? filteredUsers : BlogData?.slice(1)).map(
                    (service, index) => {
                      const {
                        head,
                        content,
                        imageCover,
                        imageThumb,
                        publishedAt,
                        content2,
                        link,
                        uniqueUrlName,
                        id,
                        imageUrl,
                      } = service;

                      return (
                        <Grid item xs={12} sm={6} lg={4} md={2}>
                          <BlogsCard
                            dec={content2}
                            head={head}
                            content={content}
                            imageCover={imageUrl}
                            imageThumb={imageUrl}
                            uniqueUrlName={uniqueUrlName}
                            link={link}
                            publishedAt={publishedAt}
                            id={id}
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )}
            </div>
          )}
        </Media>

        {/* above code for small screen */}

        <Media greaterThan="sm">
          <ToolBarSection business={false} />
          <Header />
          <div style={{ marginTop: "19rem", alignItems: "flex-start" }}>
            <h1 className="BlogsUnderline">المدونة</h1>
            <p style={{ direction: "rtl" }} className="">
              يوفر تطبيق شورى استشارات وخدمات قانونية عن طريق ربط العملاء
              بمجموعة من المحامين المرخصين من قبل وزارة العدل السعودية، مما
              يعطيهم فرصة للحصول على المشورة القانونية بسهولة ومهنية عالية.
            </p>
          </div>
          <input
            className={search ? "" : "icon"}
            placeholder="بحث عن .."
            type="search"
            value={search}
            onChange={handleInputChange}
            style={{
              width: "25%",
              height: "4.5rem",
              backgroundColor: "white",
              border: "1px solid #ebebeb",
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              marginBlockStart: "3rem",
              borderRadius: "0.8rem",
              direction: "rtl",
              padding: "1rem",
            }}
          ></input>

          {!search && (
            <div style={{ marginTop: "5rem", alignItems: "flex-start" }}>
              <p
                style={{
                  fontSize: "2rem",
                  direction: "rtl",
                  marginBottom: "2rem",
                }}
              >
                المقالات الاخيرة
              </p>
              {BlogData?.slice(0, 1)?.map((service, index) => {
                const {
                  head,
                  content,
                  imageCover,
                  imageThumb,
                  publishedAt,
                  content2,
                  uniqueUrlName,
                  link,
                  id,
                  imageUrl,
                } = service;
                return (
                  <BlogsCard
                    latest
                    head={head}
                    content={content}
                    imageCover={imageUrl}
                    imageThumb={imageUrl}
                    publishedAt={publishedAt}
                    uniqueUrlName={uniqueUrlName}
                    link={link}
                    id={id}
                    dec={content2}
                  />
                );
              })}
            </div>
          )}

          {BlogData?.length > 1 && (
            <div style={{ marginTop: "5rem", alignItems: "flex-start" }}>
              <p
                style={{
                  fontSize: "2rem",
                  direction: "rtl",
                  marginBottom: "2rem",
                }}
              >
                {search ? `نتائج البحث` : `مقالات اخرى`}
              </p>
              {filteredUsers?.length < 1 ? (
                <StaticImage
                  src="../images/blogs/Not-found.png"
                  alt="app-image"
                  placeholder="blurred"
                  objectFit="contain"
                  style={{
                    width: "20%",
                    height: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                  }}
                />
              ) : (
                <Grid
                  direction={"row-reverse"}
                  rowSpacing={5}
                  columnSpacing={5}
                  container
                >
                  {(search ? filteredUsers : BlogData?.slice(1))?.map(
                    (service, index) => {
                      const {
                        head,
                        content2,
                        content,
                        imageCover,
                        imageThumb,
                        publishedAt,
                        link,
                        uniqueUrlName,
                        id,
                        imageUrl,
                      } = service;

                      return (
                        <Grid item xs={12} sm={6} lg={4} md={2}>
                          <BlogsCard
                            dec={content2}
                            head={head}
                            uniqueUrlName={uniqueUrlName}
                            content={content}
                            imageCover={imageUrl}
                            imageThumb={imageUrl}
                            publishedAt={publishedAt}
                            link={link}
                            id={id}
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )}
            </div>
          )}
        </Media>
      </ContainerStyle>

      <div></div>
      <div dir="rtl" style={{ marginTop: "3rem" }}>
        <Media at="sm">
          <FooterMobile />
        </Media>
        <Media greaterThan="sm">
          {/* <Pagination  sx={{
    '& > .MuiPagination-ul': {
      justifyContent: 'center',
    },
  }} size="large" count={20} color="standard"
            />  */}
          <Footer />
        </Media>
      </div>

      <Gradient></Gradient>
      <WhatsappButton />
    </>
  );
};

export default Blogs;
